.TCPchatRoom {

  @media screen and (min-width: 600px) {
    img:not([alt="github"]) {
      width: 22em;
    }
  }

  @media screen and (min-width: 960px) {
    img:not([alt="github"]) {
      width: 22em;
    }
  }
}
