.Home {
  .welcomeSect {
    margin-bottom: 1em;
  }

  .welcomeParagraphs {
    padding: 0.5em 1.7em 0.2em 1.7em;
    margin: 0.3em 0;
  }

  .highlight {
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background: var(--cyan-500);
      transition: width 1.3s;
    }
    &:hover::after {
      width: 100%;
    }
  }

  .highlightLine {
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: var(--cyan-500);
    }
  }

  section {
    padding-top: 1em;
    display: inline-block;
    line-height: 1.5em;
  }

  h1,
  h2 {
    padding: 0.5em 2em 0 0.7em;
    font-style: italic;
    font-weight: 300;
    margin: 0.4em 0 0.1em 0;
  }

  h4 {
    font-weight: 700;
    color: black;
    margin: 0;
    padding: 0;
    display: inline;
  }

  img {
    margin: 0.5em 2.75em 0 1em;
    height: auto;
    float: left;
    border-radius: 50%;
    -webkit-shape-outside: circle();
    shape-outside: circle();
  }

  ul {
    list-style: square;
  }

  .spreadVertically {
    display: flex;
    flex-direction: column;
    min-height: 75%;
    justify-content: space-evenly;
  }

  .education ul {
    list-style: none;
    line-height: 1.5em;
  }

  .detail {
    list-style: square;
  }

  li {
    animation: slide-in-left 1s linear forwards;
  }

  @keyframes slide-in-left {
    from {
      margin-left: -50px;
    }
    to {
      margin-left: 0;
    }
  }

  .half {
    display: flex;
    padding-top: 0;
  }

  .skills {
    width: 100vw;
    padding: 1em;
    border-top: 2px solid var(--red-500);
  }

  .education {
    width: 100vw;
    padding: 1em;
    border-top: 2px solid var(--gold-500);
  }

  .languages {
    width: 100vw;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    min-height: 6vh;
    border: 2px solid var(--cyan-500);
  }

  .languages p {
    padding: 0;
    margin: 0 0 1em 0;
    font-size: 1em;
    font-weight: 400;
  }

  .slide {
    width: 15vw;
    animation-duration: 2s;
    animation-name: slidein;
  }

  @keyframes slidein {
    from {
      margin-left: 3%;
      width: 10%;
    }
    to {
      margin-left: 1em;
      width: 15vw;
    }
  }

  .imgDiv {
    margin-left: 1em;
    min-width: 15vw;
    animation-name: spin;
    animation-duration: 800ms;
  }

  .pWelcome1 {
    padding: 0 1.7em;
    margin-top: 0;
  }

  .fadeIn {
    animation: fadein 1s;
    -moz-animation: fadein 1s; /* Firefox */
    -webkit-animation: fadein 1s; /* Safari and Chrome */
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    /* Firefox */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(-20deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  /* fix huge font sizes on iphone 5/6/7 */
  @media screen and (max-width: 668px) and (orientation: landscape) {
    .minFont {
      font-size: 0.725em;
      line-height: initial;
    }
  }

  @media only screen and (max-width: 600px) and (min-width: 200px) {
    .half {
      display: block;
    }
  }

  @media screen and (min-width: 960px) {
    img {
      max-width: 9em;
    }
  }
}
