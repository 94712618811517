@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,700');

* {
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-weight: 300;
  background-color: var(--bg-color);
  color: var(--text-color);
  height: 100%;
}

header {
  background-color: var(--bg-color);
  width: 100%;
  top: 0;
  position: fixed;
}

main {
  padding-top: 4.5em;
  margin: 0;
  background-color: var(--bg-color);
}

footer {
  width: 100%;
  padding: 0.5em;
  line-height: 1.5em;
  background-color: var(--bg-color);
}

