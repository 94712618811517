.ProjectTemplate {
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  h1 {
    padding: 0;
    font-style: italic;
    font-weight: 300;
  }

  article {
    max-width: 80vw;
    margin-bottom: 1em;
  }

  p {
    width: 70vw;
    background: var(--gold-500);
    padding: 1em;
    animation: slide-in-left 1s linear forwards;
    transition: background-color 1s ease;
  }

  p:hover {
    background-color: pink;
  }

  img:not([alt="github"]) {
    margin-bottom: 3em;
    filter: drop-shadow(0.2em 0.2em 0.45em rgb(71, 71, 71));
    max-width: 100vw;
  }

  img[alt="github"] {
    width: 2em;
    &:hover {
      animation: shake 0.65s ease-in-out;
    }
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(-0.25em);
    }
    40% {
      transform: translateX(0.22em);
    }
    60% {
      transform: translateX(-0.25em);
    }
    80% {
      transform: translateX(0.22em);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slide-in-left {
    from {
      margin-left: -50px;
    }
    to {
      margin-left: 0;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
