.Projects {
  display: flex;
  align-items: flex-start;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */

  p.description {
    padding: 1.7em 1.7em 0.2em 1.7em;
    animation-duration: 1s;
    width: 100%;
  }

  .imgs {
    display: block;
    text-align: center;
  }

  img {
    width: 23em;
    filter: drop-shadow(0.16em 0.11em 0.1em rgb(104, 103, 103));
    justify-self: center;
    padding-top: 2em;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slidein {
    from {
      margin-left: 50%;
      width: 100%;
    }
    to {
      margin-left: 0%;
      width: 100%;
    }
  }

  @keyframes slideinRight {
    from {
      margin-left: -50%;
      width: 100%;
    }
    to {
      margin-right: 0%;
      width: 100%;
    }
  }

  section:nth-of-type(2) > article > .noWrap {
    white-space: nowrap;
  }

  section {
    margin: 0 0.5em 0 0.5em;
    line-height: 1.5em;
    width: 49%;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    min-height: 75%;
    justify-content: space-evenly;

    ul {
      list-style: none;
      padding: 1rem 0;
    }

    a {
      margin: 1em 2em;
    }

    .twitter {
      margin: 0 !important;
    }

    p.caption {
      font-size: 0.85rem;
      text-align: center;
      margin-right: 2em;
      margin-left: 2em;
    }
  }

  .right {
    float: right;
    padding-top: 1em;
  }

  .left {
    float: left;
  }

  h1 {
    font-size: 2em;
  }

  h1,
  h2 {
    padding: 0.5em 2em 0 0.75em;
    font-style: italic;
    font-weight: 300;
    margin: 0.4em 0 0.1em 0;
  }

  h3 {
    display: inline;
    font-style: italic;
    font-weight: 700;
  }

  article {
    padding: 0.8em 0.8em 0 0.8em;

    @media (hover) {
      &::after {
        content: "";
        display: block;
        width: 0;
        margin-top: 1em;
        height: 2px;
        background-image: repeating-linear-gradient(
          to left,
          transparent,
          var(--cyan-500),
          var(--red-500),
          var(--gold-500)
        );
        transition: width 0.4s;
      }
      &:hover::after {
        width: 100%;
      }
    }
  }

  article span a {
    line-height: 2.3em;
  }

  @media only screen and (max-width: 600px) and (min-width: 200px) {
    display: block;

    .left,
    .right {
      width: 100vw;
    }

    section {
      margin: 0;
    }
    section.left {
      border-bottom: 2px solid lightgray;
    }

    article {
      padding: 0.5em 1.7em 1em 1.7em;
      width: 100vw;
    }
  }

  @media only screen and (max-width: 888px) {
    img {
      width: 80%;
      margin: 0 auto;
    }

    section:first-of-type {
      display: block;
    }
  }

  /* fix huge font sizes on iphone 5 */
  @media screen and (max-width: 568px) and (orientation: landscape) {
    .description,
    span {
      font-size: 0.725em;
      line-height: initial;
    }
  }

  @media screen and (min-width: 601px) {
    .rightContainer {
      max-height: 1310px;
      overflow: auto;
      border: 2px solid var(--gold-500);
    }
  }
}
