.AsyncRM {
  img[alt="github"] {
    margin-top: 1rem;
  }

  @media screen and (min-width: 600px) {
    img:not([alt="github"]) {
      width: 30em;
    }
  }
}
